/* eslint-disable */

import React, { Component } from "react";
import InputRange from "react-input-range";
import "./ActivityListing.css";
import {Helmet} from "react-helmet";
import {
  TransferSourceZoneData,
  TransferAirportType,  
  TransferFerryType, 
  TransferCityType,
  TransferToAirportCityType,
  TransferCityToAirportType, 
  TransferCityToCityType,
  getTourTransferList,
  getTourFilterTransfer, getalltransport
} from "../Component/axios";
import Autosuggest from "react-autosuggest";
import NewHeader from "../Component/NewHeader/NewHeader";
import moment from "moment";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import HotelListSkeleton from "../Component/HostelList/HotelListSkeleton";
import TourTransferList from "../TourList/TourTransferList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import  ExperiencesSoldout  from "./SoldOut/ExperiencesSoldout";
import TawkToWidget from "../Component/TawkToWidget";
const SortArr = [
  { id: "4", name: "Recommended" },
  { id: "1", name: "Price - Low to High" },
  { id: "2", name: "Price - High to Low" },
  // { id: "3", name: "User Rating - High to Low" },
];
const Duration = [
  { id: "1", name: "Up to 1 hour" },
  { id: "2", name: "1 to 4 hours" },
  { id: "3", name: "4 hours to 1 day" },
  { id: "4", name: "1 to 3 days" },
  { id: "5", name: "3+ days" },
];

const TimeOfDays = [
  { id: "1", name: "Morning", smallLabel:"Starts before 12 pm" },
  { id: "2", name: "Afternoon", smallLabel:"Starts after 12 pm"  },
  { id: "3", name: "Evening and night", smallLabel:"Starts after 5 pm"  },
];

export default class TransferListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: null,
      // endDate: null,
      // isOpen: false,
      dropdownVisible: false,
     // selected: "Accommodations",
      searchInput: "",
      error: null,
      val: "",
      //showResults: false,
      SelectedPlace: null,
      nav: false,
      searchData: "",
      sorting: false,
      sortingSelect: "Recommended",
      isDropdownOpen: false,
      sortingId: null,
      value: "",
      valueSourceTransfer: "",
      valueDestinationTransfer: "",
      valueAirportTransfer: "",
      valueToAirportTransfer: "",
      valueAirportToCityTransfer: "",
      valueFerryTransfer: "",
      valueFerryToTransfer: "",
      valueCityTransfer: "",
      suggestionsTransfer: [],
      suggestionsSourceTransfer: [],
      suggestionsDestinationTransfer: [],
      suggestionsAirportTransfer: [],
      suggestionsToAirportTransfer: [],
      suggestionsAirportToCityTransfer: [],
      suggestionsFerryTransfer: [],
      suggestionsFerryToTransfer: [],
      suggestionsCityTransfer: [],
      showResultsTransfer: false,
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      defaultZonevalTransfer: "Seychelles",
      defaultZonevalSourceTransfer: "Victoria",
      defaultZonevalDestinationTransfer: "Anse Royale",
      defaultZonevalAirportTransfer: "Seychelles International Airport, Mahe Island",
      defaultZonevalToAirportTransfer: "Seychelles International Airport, Mahe Island",
      defaultZonevalAirportToCityTransfer: "Victoria",
      defaultZonevalFerryTransfer: "Mahe Jetty Port",
      defaultZonevalFerryToTransfer: "Praslin Island Port",
      defaultZonevalCityTransfer: "Victoria",
      selectedTransfertype: "1",
      transfertype:"1",
      selectPickupType:"1",
      pickuptype:"1",
      selectTransferDay: moment(new Date()).format("ddd, D MMM yy"),
      selectTransferDayNew: new Date(),
      checkinShowTransfer: false,
      transferData: null,
      FiltersData: null,
      valuePrice: { min: 0, max: 10 },
      appliedFilterVal: [],
      checked: false,
      checkedItems: new Map(),
      transferList: null,
      transferListFilter: null,
      checkedTime: false,
      showPickupType:false,
      showFromAirPort:false,
      showToAirPort:false,
      ShowTransportType:true,
      showFerryType:false,
      checked: [],
      expanded: [],
      CategotyThem:null
    };
  }

  componentDidMount() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    const defaultZonevalSourceTransferVal = urlParams.get(
      "defaultZonevalSourceTransferVal"
    );
    const defaultZonevalDestinationTransferVal = urlParams.get(
      "defaultZonevalDestinationTransferVal"
    );
    const defaultZonevalAirportTransferVal = urlParams.get(
      "defaultZonevalAirportTransferVal"
    );
    const defaultZonevalAirportToCityTransferVal = urlParams.get(
      "defaultZonevalAirportToCityTransferVal"
    );
    const defaultZonevalToAirportTransferVal = urlParams.get(
      "defaultZonevalToAirportTransferVal"
    );
    const defaultZonevalFerryTransferVal = urlParams.get(
      "defaultZonevalFerryTransferVal"
    );
    const defaultZonevalFerryToTransferVal = urlParams.get(
      "defaultZonevalFerryToTransferVal"
    );
    const defaultZonevalCityTransferVal = urlParams.get(
      "defaultZonevalCityTransferVal"
    );
    const transfertype = urlParams.get(
      "transfertype"
    );
    const pickuptype = urlParams.get(
      "pickuptype"
    );
    const dateSelected = urlParams.get("dateSelected");
    
    this.setState({ defaultZonevalSourceTransfer: defaultZonevalSourceTransferVal });
    this.setState({ defaultZonevalDestinationTransfer: defaultZonevalDestinationTransferVal });
    this.setState({ defaultZonevalAirportTransfer: defaultZonevalAirportTransferVal });
    this.setState({ defaultZonevalToAirportTransfer: defaultZonevalToAirportTransferVal });
    this.setState({ defaultZonevalAirportToCityTransfer: defaultZonevalAirportToCityTransferVal });
    this.setState({ defaultZonevalFerryTransfer: defaultZonevalFerryTransferVal });
    this.setState({ defaultZonevalFerryToTransfer: defaultZonevalFerryToTransferVal });
    this.setState({ defaultZonevalCityTransfer: defaultZonevalCityTransferVal });
    this.setState({ selectedTransfertype: transfertype });
    this.setState({ selectPickupType: pickuptype });

    this.setState({ selectTransferDayNew: dateSelected });
    this.setState({
      selectTransferDay: moment(dateSelected).format("ddd, D MMM yy"),
    });

    //Edit Show Details
    if(transfertype == 1){
      this.setState({ showPickupType:true })
      this.setState({ showFromAirPort:true })
      this.setState({ ShowTransportType:false })
      this.setState({ showFerryType:false })
    } else if(transfertype == 3){
      this.setState({ showFerryType:true })
      this.setState({ showPickupType:false })
      this.setState({ ShowTransportType:false })
      this.setState({ showToAirPort:false })
      this.setState({ showFromAirPort:false })  
    }else{
        this.setState({ showPickupType:false })
        this.setState({ ShowTransportType:true })
        this.setState({ showToAirPort:false })
        this.setState({ showFromAirPort:false })
        this.setState({ showFerryType:false })
    }
    //Pickup Edit Show Details
    if(transfertype == 1 && pickuptype == 1){
        this.setState({ showFromAirPort:true })
        this.setState({ showToAirPort:false })
        this.setState({ ShowTransportType:false })
       
        // Airport To City
        TransferToAirportCityType(defaultZonevalAirportTransferVal).then(
          (data) => {
            
              this.setState({
                  itemsZoneAirportToCityTransfer: data,
              });
          },
          (error) => {
              this.setState({
                  error
              });
          }
      );
    }else if(transfertype == 1 && pickuptype == 2){
        this.setState({ showToAirPort:true })
        this.setState({ showFromAirPort:false })
        this.setState({ ShowTransportType:false })


        TransferCityToAirportType(defaultZonevalToAirportTransferVal).then(
          (data) => {
            
              this.setState({
                itemsZoneToAirportTransfer: data,
              });
          },
          (error) => {
              this.setState({
                  error
              });
          }
      );
    }
   
    let JsonState = {
     // defaultZonevalTransferVal: defaultZonevalTransferVal,
      defaultZonevalSourceTransferVal: defaultZonevalSourceTransferVal,
      defaultZonevalDestinationTransferVal: defaultZonevalDestinationTransferVal,
      defaultZonevalAirportTransferVal: defaultZonevalAirportTransferVal,
      defaultZonevalToAirportTransferVal: defaultZonevalToAirportTransferVal,
      defaultZonevalAirportToCityTransferVal: defaultZonevalAirportToCityTransferVal,
      defaultZonevalFerryTransferVal: defaultZonevalFerryTransferVal,
      defaultZonevalFerryToTransferVal: defaultZonevalFerryToTransferVal,
      defaultZonevalCityTransferVal: defaultZonevalCityTransferVal,
      transfertype: transfertype,
      pickuptype: pickuptype,
      dateSelected: moment(dateSelected).format("Y-MM-DD"),
    };
    const hotelArr = JSON.stringify(JsonState);
   // console.log(JSON.stringify(JsonState));

    // TransferZoneData().then(
    //   (data) => {
    //     this.setState({
    //       itemsZoneTransfer: data,
    //     });
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    TransferSourceZoneData().then(
      (data) => {
        this.setState({
          itemsZoneSourceTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    const tour_type= {tour_type:"Transfer"}
    const tourtype = JSON.stringify(tour_type);
      getalltransport(tourtype).then(
        (data) => {
        
          
          this.setState({ CategotyThem:data.transport_mode_name});
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );

        //City To City
    TransferCityToCityType(defaultZonevalSourceTransferVal).then(
      (data) => {
        this.setState({
          itemsZoneDestinationTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    // TransferDestinationZoneData().then(
    //   (data) => {
    //     this.setState({
    //       itemsZoneDestinationTransfer: data,
    //     });
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    TransferAirportType().then(
      (data) => {
        this.setState({
          itemsZoneAirportTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    
    // Airport To City
    TransferToAirportCityType(defaultZonevalAirportTransferVal).then(
      (data) => {
         console.log(data);
        this.setState({
          itemsZoneAirportToCityTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    //City To Airport
    TransferCityToAirportType(defaultZonevalCityTransferVal).then(
        (data) => {
            this.setState({

              itemsZoneToAirportTransfer: data,
            });
        },
        (error) => {
            this.setState({
                error
            });
        }
    );

    TransferFerryType().then(
      (data) => {
        this.setState({
          itemsZoneFerryTransfer: data,
        });
        this.setState({
          itemsZoneFerryToTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    TransferCityType().then(
      (data) => {
        this.setState({
          itemsZoneCityTransfer: data,
        });
      // console.log(data);
        this.setState({
              itemsZoneAirportToCityTransfer: data,
          });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    TransferCityToAirportType(defaultZonevalCityTransferVal).then(
      (data) => {
        
          this.setState({
            itemsZoneToAirportTransfer: data,
          });
      },
      (error) => {
          this.setState({
              error
          });
      }
  );

  //   TransferToAirportCityType(defaultZonevalAirportTransferVal).then(
  //     (data) => {
      
  //         this.setState({

  //             itemsZoneAirportToCityTransfer: data,
  //         });
  //     },
  //     (error) => {
  //         this.setState({
  //             error
  //         });
  //     }
  // );
  

    getTourTransferList(hotelArr).then(
      (data) => {
        // this.setState({ transferData: data });
        if (data == undefined) {
          this.setState({
            transferData: null,
          });
        } else {
         
          this.setState({
            transferListFilter: data,
          });

          if (data == "no_transfer") {
            this.setState({
              transferData: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    let JsonState1 = {
     // defaultZonevalVal: defaultZonevalTransferVal,
      defaultZonevalSourceVal: defaultZonevalSourceTransferVal,
      defaultZonevalDestinationVal: defaultZonevalDestinationTransferVal,
      defaultZonevalAirportVal: defaultZonevalAirportTransferVal,
      defaultZonevalToAirportVal: defaultZonevalToAirportTransferVal,
      defaultZonevalAirportToCityVal: defaultZonevalAirportToCityTransferVal,
      defaultZonevalFerryVal: defaultZonevalFerryTransferVal,
      defaultZonevalFerryToVal: defaultZonevalFerryToTransferVal,
      defaultZonevalCityVal: defaultZonevalCityTransferVal,
      transfertype:transfertype,
      pickuptype: pickuptype,
      dateSelected: moment(dateSelected).format("Y-MM-DD"),
    };
    const hotelArr1 = JSON.stringify(JsonState1);

    getTourFilterTransfer(hotelArr1).then(
      (data) => {
      
        this.setState({ FiltersData: data });
        this.setState({ valuePrice: { min: 0, max: data?.max_price.toFixed(2) } });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  SearchListing = () => {
    this.setState({
      FiltersData: null,
    });
    this.setState({
      transferData: null,
    });
    this.setState({
      sortingSelect: "Recommended",
    });

    let JsonState = {
     // defaultZonevalTransferVal: this.state.defaultZonevalTransfer,
      defaultZonevalSourceTransferVal: this.state.defaultZonevalSourceTransfer,
      defaultZonevalDestinationTransferVal: this.state.defaultZonevalDestinationTransfer,
      defaultZonevalAirportTransferVal: this.state.defaultZonevalAirportTransfer,
      defaultZonevalToAirportTransferVal: this.state.defaultZonevalToAirportTransfer,
      defaultZonevalAirportToCityTransferVal: this.state.defaultZonevalAirportToCityTransfer,
      defaultZonevalFerryTransferVal: this.state.defaultZonevalFerryTransfer,
      defaultZonevalFerryToTransferVal: this.state.defaultZonevalFerryToTransfer,
      defaultZonevalCityTransferVal: this.state.defaultZonevalCityTransfer,
      transfertype: this.state.selectedTransfertype,
      pickuptype: this.state.selectPickupType,
      dateSelected: moment(this.state.selectTransferDayNew).format("Y-MM-DD"),
    };
    const hotelArr = JSON.stringify(JsonState);
   // console.log(JSON.stringify(JsonState));

    // TransferZoneData().then(
    //   (data) => {
    //     this.setState({
    //       itemsZoneTransfer: data,
    //     });
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    TransferSourceZoneData().then(
      (data) => {
        this.setState({
          itemsZoneSourceTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // TransferDestinationZoneData().then(
    //   (data) => {
    //     this.setState({
    //       itemsZoneDestinationTransfer: data,
    //     });
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    TransferAirportType().then(
      (data) => {
        this.setState({
          itemsZoneAirportTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    TransferFerryType().then(
      (data) => {
        this.setState({
          itemsZoneFerryTransfer: data,
        });
        this.setState({
          itemsZoneFerryToTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    TransferCityType().then(
      (data) => {
        this.setState({
          itemsZoneCityTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getTourTransferList(hotelArr).then(
      (data) => {
      
        if (data == undefined) {
          this.setState({
            transferData: null,
          });
        } else {
         
          this.setState({
            transferListFilter: data,
          });

          if (data == "no_transfer") {
            this.setState({
              transferData: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    let JsonState1 = {
      defaultZonevalSourceVal: this.state.defaultZonevalSourceTransfer,
      defaultZonevalDestinationVal: this.state.defaultZonevalDestinationTransfer,
      defaultZonevalAirportVal: this.state.defaultZonevalAirportTransfer,
      defaultZonevalToAirportVal: this.state.defaultZonevalToAirportTransfer,
      defaultZonevalAirporToCityVal: this.state.defaultZonevalAirportToCityTransfer,
      defaultZonevalFerryVal: this.state.defaultZonevalFerryTransfer,
      defaultZonevalFerryToVal: this.state.defaultZonevalFerryToTransfer,
      defaultZonevalCityVal: this.state.defaultZonevalCityTransfer,
      transfertype: this.state.selectedTransfertype,
      pickuptype: this.state.selectPickupType,
      dateSelected: moment(this.state.selectTransferDayNew).format("Y-MM-DD"),
     };
    const hotelArr1 = JSON.stringify(JsonState1);

    getTourFilterTransfer(hotelArr1).then(
      (data) => {
        this.setState({ FiltersData: data });
        this.setState({ valuePrice: { min: 0, max: data?.max_price.toFixed(2) } });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  
  SortingFunction = (data) => {
    if (data != "no_transfer") {
   
      const sorArr = data;
      setTimeout(() => {
       

        // const getValue = (price) => +price;
        // sorArr.sort(
        //   (a, b) =>
        //     getValue(
        //       Object.values(a).map((i, j) => {
        //         return i.tour_price;
        //       })
        //     ) -
        //     getValue(
        //       Object.values(b).map((i, j) => {
        //         return i.tour_price;
        //       })
        //     )
        // );

        this.setState({
          transferData: sorArr,
        });
        this.setState({ transferListFilter: sorArr });
      }, 2000);
    }
  };

  sortingSearch = (data) => {
  
    const sorArr = data;
    if (this.state.sortingSelect == "Price - Low to High") {
 

      setTimeout(() => {
        if (sorArr[0].matched) {
          const sliceArr = sorArr.shift();
          const getValue = (price) => +price;

          sorArr.sort(
            (a, b) =>
              getValue(
                Object.values(a).map((i, j) => {
                  return i.tour_price;
                })
              ) -
              getValue(
                Object.values(b).map((i, j) => {
                  return i.tour_price;
                })
              )
          );

          

          this.setState({
            transferData: sorArr,
          });

          this.setState({ transferListFilter: sorArr });
        } else {
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                Object.values(a).map((i, j) => {
                  return i.tour_price;
                })
              ) -
              getValue(
                Object.values(b).map((i, j) => {
                  return i.tour_price;
                })
              )
          );

  

          this.setState({
            transferData: sorArr,
          });
          this.setState({ transferListFilter: sorArr });
        }
      }, 2000);
    } else if (this.state.sortingSelect == "Price - High to Low") {
      setTimeout(() => {
        const getValue = (price) => -price;

        // const getValue = (price) => +price;
        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

      

        this.setState({
          transferData: sorArr,
        });
        this.setState({ transferListFilter: sorArr });
      }, 2000);
    } else if (this.state.sortingSelect == "Recommended") {
      setTimeout(() => {
        const getValue = (id) => id

        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_data.id;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_data.id;
              })
            )
        );

      

        this.setState({
          transferData: sorArr,
        });
        this.setState({ transferListFilter: sorArr });
      }, 2000);
    }
  };

  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("scroll", this.handleScroll);
  }
  globalClickListener = (nativeEvent) => {
    this.setState(
      {
        isDropdownOpen: false,
        dropdownVisible: false,
        showResultsSourceTransfer: false,
        showResultsDestinationTransfer: false,
        showResultsAirportTransfer: false,
        showResultsToAirportTransfer: false,
        showResultsAirportToCityTransfer: false,
        showResultsFerryTransfer: false,
        showResultsFerryToTransfer: false,
        showResultsCityTransfer: false,
        checkinShowTransfer: false,
      },
      () => {
      
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };

  checkinandcheckoutTransfer = (syntheticEvent) => {
    this.setState(
      {
        isDropdownOpen: false,
        dropdownVisible: false,
        showResultsSourceTransfer: false,
        showResultsDestinationTransfer: false,
        showResultsAirportTransfer: false,
        showResultsToAirportTransfer: false,
        showResultsAirportToCityTransfer: false,
        showResultsFerryTransfer: false,
        showResultsFerryToTransfer: false,
        showResultsCityTransfer: false
      });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowTransfer: !prevState.checkinShowTransfer }),
      () => {
        if (this.state.checkinShowTransfer) {
    
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
 
  };

  handleBodyClick = (event) => {

    event.stopPropagation();
  };

  showSorting = (syntheticEvent) => {

    syntheticEvent.stopPropagation();
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
    this.setState(
      (prevState) => ({ sorting: !prevState.sorting }),
      () => {
        if (this.state.sorting) {
    
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  // getSuggestionsTransfer = (value) => {
  //   const inputValue = value.trim().toLowerCase();
  //   const inputLength = inputValue.length;

  //   return inputLength === 0
  //     ? []
  //     : this.state.itemsZoneTransfer.filter(
  //         (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
  //       );
  // };

  getSuggestionsSourceTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneSourceTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsDestinationTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneDestinationTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsAirportTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneAirportTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsToAirportTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneToAirportTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsAirportToCityTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneAirportToCityTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsFerryTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneFerryTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsFerryToTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneFerryToTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsCityTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneCityTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // onSuggestionsFetchRequestedTransfer = ({ value }) => {
  //   this.setState({
  //     suggestionsTransfer: this.getSuggestionsTransfer(value),
  //   });
  // };

  onSuggestionsFetchRequestedSourceTransfer = ({ value }) => {
    this.setState({
      suggestionsSourceTransfer: this.getSuggestionsSourceTransfer(value),
    });
  };
  onSuggestionsFetchRequestedDestinationTransfer = ({ value }) => {
    this.setState({
      suggestionsDestinationTransfer: this.getSuggestionsDestinationTransfer(value),
    });
  };
  onSuggestionsFetchRequestedAirportTransfer = ({ value }) => {
    this.setState({
      suggestionsAirportTransfer: this.getSuggestionsAirportTransfer(value),
    });
  };
  onSuggestionsFetchRequestedToAirportTransfer = ({ value }) => {
    this.setState({
      suggestionsToAirportTransfer: this.getSuggestionsToAirportTransfer(value),
    });
  };
  onSuggestionsFetchRequestedAirportToCityTransfer = ({ value }) => {
    this.setState({
      suggestionsAirportToCityTransfer: this.getSuggestionsAirportToCityTransfer(value),
    });
  };
  onSuggestionsFetchRequestedFerryTransfer = ({ value }) => {
    this.setState({
      suggestionsFerryTransfer: this.getSuggestionsFerryTransfer(value),
    });
  };
  onSuggestionsFetchRequestedFerryToTransfer = ({ value }) => {
    this.setState({
      suggestionsFerryToTransfer: this.getSuggestionsFerryToTransfer(value),
    });
  };
  onSuggestionsFetchRequestedCityTransfer = ({ value }) => {
    this.setState({
      suggestionsCityTransfer: this.getSuggestionsCityTransfer(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  // onSuggestionsClearRequestedTransfer = () => {
  //   this.setState({
  //     suggestionsTransfer: [],
  //   });
  // };
  onSuggestionsClearRequestedSourceTransfer = () => {
    this.setState({
        suggestionsSourceTransfer: []
    });
};
onSuggestionsClearRequestedDestinationTransfer = () => {
    this.setState({
        suggestionsDestinationTransfer: []
    });
};
onSuggestionsClearRequestedAirportTransfer = () => {
  this.setState({
      suggestionsAirportTransfer: []
  });
};
onSuggestionsClearRequestedToAirportTransfer = () => {
  this.setState({
      suggestionsToAirportTransfer: []
  });
};
onSuggestionsClearRequestedToAirportToCityTransfer = () => {
  this.setState({
      suggestionsToAirportToCityTransfer: []
  });
};
onSuggestionsClearRequestedFerryTransfer = () => {
  this.setState({
      suggestionsFerryTransfer: []
  });
};
onSuggestionsClearRequestedFerryToTransfer = () => {
  this.setState({
      suggestionsFerryToTransfer: []
  });
};
onSuggestionsClearRequestedCityTransfer = () => {
  this.setState({
      suggestionsCityTransfer: []
  });
};

  // onSuggestionSelectedTransfer = () => {
  //   this.setState({
  //     showResultsTransfer: false,
  //   });
  // };

  onSuggestionSelectedSourceTransfer = () => {
      this.setState({
          showResultsSourceTransfer: false
      });
  }
  onSuggestionSelectedDestinationTransfer = () => {
      this.setState({
          showResultsDestinationTransfer: false
      });
  }
  onSuggestionSelectedAirportTransfer = () => {
    this.setState({
        showResultsAirportTransfer: false
    });
}

onSuggestionSelectedToAirportTransfer = () => {
  this.setState({
      showResultsToAirportTransfer: false
  });
}
onSuggestionSelectedAirportToCityTransfer = () => {
  this.setState({
      showResultsAirportToCityTransfer: false
  });
}
onSuggestionSelectedFerryTransfer = () => {
  this.setState({
      showResultsFerryTransfer: false
  });
}
onSuggestionSelectedFerryToTransfer = () => {
  this.setState({
      showResultsFerryToTransfer: false
  });
}
onSuggestionSelectedCityTransfer = () => {
  this.setState({
      showResultsCityTransfer: false
  });
}
 // getSuggestionValueTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueSourceTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueDestinationTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueAirportTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueToAirportTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueAirportToCityTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueFerryTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueFerryToTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueCityTransfer = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  // renderSuggestionTransfer = (suggestion, { query, isHighlighted }) => (
  //   <div className="font14 darkText  noChild  clickable">
  //     <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
  //       {suggestion.LocationImage != "nolocation" ? (
  //         <div className="locationImage">
  //           <img
  //             src={suggestion.LocationImage}
  //             className="locationImageContent"
  //           />
  //         </div>
  //       ) : (
  //         <div className="tourImage">
  //           {" "}
  //           <img src={suggestion.Image} className="locationImageContent2" />
  //         </div>
  //       )}
  //       <div className="flexOne">
  //         <p className="locusLabel appendBottom5 fontSizeChange">
  //           {suggestion.Name}
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );

  
  renderSuggestionSourceTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );

  renderSuggestionDestinationTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );

  renderSuggestionAirportTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionToAirportTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionAirportToCityTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionFerryTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionFerryToTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionCityTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  // renderSuggestionsContainerTransfer = ({ containerProps, children }) => {
  //   return (
  //     <div {...containerProps}>
  //       <div class="react-autosuggest__section-title">
  //         <label class="hsw_sectionTitle font12 latoBlack greyText">
  //           SUGGESTIONS{" "}
  //         </label>
  //       </div>
  //       <div> {children}</div>
  //     </div>
  //   );
  // };

  renderSuggestionsContainerSourceTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerDestinationTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerAirportTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerToAirportTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerAirportToCityTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerFerryTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerFerryToTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerCityTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  // showZonelistdivTransfer = (syntheticEvent) => {
  //   syntheticEvent.stopPropagation();
  //   this.setState(
  //     (prevState) => ({ showResultsTransfer: !prevState.showResultsTransfer }),
  //     () => {
  //       if (this.state.showResultsTransfer) {
  

  //         // document.addEventListener('click', this.globalClickListener)
  //         window.addEventListener("click", this.globalClickListener);
  //       }
  //     }
  //   );
  // };

  showZonelistdivSourceTransfer = (syntheticEvent) => {
    this.setState({
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsSourceTransfer: !prevState.showResultsSourceTransfer }),
      () => {
        if (this.state.showResultsSourceTransfer) {
    
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  showZonelistdivDestinationTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsDestinationTransfer: !prevState.showResultsDestinationTransfer }),
      () => {
        if (this.state.showResultsDestinationTransfer) {
      
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivAirportTransfer = (syntheticEvent) => {

    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsAirportTransfer: !prevState.showResultsAirportTransfer }),
      () => {
        if (this.state.showResultsAirportTransfer) {
 
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivToAirportTransfer = (syntheticEvent) => {

    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsToAirportTransfer: !prevState.showResultsToAirportTransfer }),
      () => {
        if (this.state.showResultsToAirportTransfer) {
    
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivAirportToCityTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsAirportToCityTransfer: !prevState.showResultsAirportToCityTransfer }),
      () => {
        if (this.state.showResultsAirportToCityTransfer) {
     
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivFerryTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsFerryTransfer: !prevState.showResultsFerryTransfer }),
      () => {
        if (this.state.showResultsFerryTransfer) {
     
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivFerryToTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsFerryToTransfer: !prevState.showResultsFerryToTransfer }),
      () => {
        if (this.state.showResultsFerryToTransfer) {
      
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  showZonelistdivCityTransfer = (syntheticEvent) => {

    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsCityTransfer: !prevState.showResultsCityTransfer }),
      () => {
        if (this.state.showResultsCityTransfer) {
       
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  // onChangeTransfer = (event, { newValue }) => {
  //   this.setState({
  //     value: newValue,
  //     defaultZonevalTransfer: newValue,
  //   });
  // };

  onChangeSourceTransfer = (event, { newValue }) => {
    
    this.setState({
      valueSourceTransfer: newValue,
      defaultZonevalSourceTransfer: newValue,
    });

      //City To City
        TransferCityToCityType(newValue).then(
            (data) => {
                this.setState({
                    itemsZoneDestinationTransfer: data,
                });
            },
            (error) => {
                this.setState({
                    error
                });
            }
        );
    
  };

  onChangeDestinationTransfer = (event, { newValue }) => {

    this.setState({
      valueDestinationTransfer:newValue,
      defaultZonevalDestinationTransfer: newValue,
    });
  };
  onChangeAirportTransfer = (event, { newValue }) => {
 
    this.setState({
      valueAirportTransfer:newValue,
      defaultZonevalAirportTransfer: newValue,
    });
     // Airport To City
     TransferToAirportCityType(newValue).then(
      (data) => {
      
          this.setState({

              itemsZoneAirportToCityTransfer: data,
          });
      },
      (error) => {
          this.setState({
              error
          });
      }
  );
  };

  onChangeToAirportTransfer = (event, { newValue }) => {

    this.setState({
      valueToAirportTransfer:newValue,
      defaultZonevalToAirportTransfer: newValue,
    });
  };

  onChangeAirportToCityTransfer = (event, { newValue }) => {

    this.setState({
      valueAirportToCityTransfer:newValue,
      defaultZonevalAirportToCityTransfer: newValue,
    });
  };
  onChangeFerryTransfer = (event, { newValue }) => {

    this.setState({
      valueFerryTransfer:newValue,
      defaultZonevalFerryTransfer: newValue,
    });
  };
  onChangeFerryToTransfer = (event, { newValue }) => {
 
    this.setState({
      valueFerryToTransfer:newValue,
      defaultZonevalFerryToTransfer: newValue,
    });
  };
  onChangeCityTransfer = (event, { newValue }) => {

    this.setState({
      valueCityTransfer:newValue,
      defaultZonevalCityTransfer: newValue,
    });

    // City To Airport
    TransferCityToAirportType(newValue).then(
      (data) => {
     
          this.setState({

              itemsZoneToAirportTransfer: data,
          });
      },
      (error) => {
          this.setState({
              error
          });
      }
  );
  };
  handleTransferType = (tranfertype) => {

    this.setState({ selectedTransfertype: tranfertype.target.value })  

    if(tranfertype.target.value == 1){
      this.setState({ showPickupType:true })
      this.setState({ showFromAirPort:true })
      this.setState({ ShowTransportType:false })
      this.setState({ showFerryType:false })
   } else if(tranfertype.target.value == 3){
      this.setState({ showFerryType:true })
      this.setState({ showPickupType:false })
      this.setState({ ShowTransportType:false })
      this.setState({ showToAirPort:false })
      this.setState({ showFromAirPort:false })
      
  }else{
      this.setState({ showPickupType:false })
      this.setState({ ShowTransportType:true })
      this.setState({ showToAirPort:false })
      this.setState({ showFromAirPort:false })
      this.setState({ showFerryType:false })
  }
}


handlePickupType = (pickuptype) => {
 
  this.setState({ selectPickupType: pickuptype.target.value })
  if(pickuptype.target.value == 1){
      this.setState({ showFromAirPort:true })
      this.setState({ showToAirPort:false })
      this.setState({ ShowTransportType:false })
     
  }else if(pickuptype.target.value == 2){
      this.setState({ showToAirPort:true })
      this.setState({ showFromAirPort:false })
      this.setState({ ShowTransportType:false })
  }else{
      this.setState({ ShowTransportType:true })
  }
 
}


handlePickupType = (pickuptype) => {

this.setState({ selectPickupType: pickuptype.target.value })
if(pickuptype.target.value == 1){
    this.setState({ showFromAirPort:true })
    this.setState({ showToAirPort:false })
    this.setState({ ShowTransportType:false })
   
}else if(pickuptype.target.value == 2){
    this.setState({ showToAirPort:true })
    this.setState({ showFromAirPort:false })
    this.setState({ ShowTransportType:false })
}else{
    this.setState({ ShowTransportType:true })
}

}

  handleTransferDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      // If the day is disabled, do nothing
      return;
    } 
    this.setState({ selectTransferDayNew: selected ? undefined : day });
    this.setState({ selectTransferDay: moment(day).format("ddd, D MMM yy") });
    this.setState({ checkinShowTransfer: false });
  };

  handleScroll = () => {
    if (window.pageYOffset > 10) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  handleSortingChange = (e) => {
    this.setState({ isDropdownOpen: false });
    this.setState({
      transferData: null,
    });

    const sorArr = this.state.transferData;
  

    if (e.id == 2) {
      setTimeout(() => {
        const getValue = (price) => -price;

        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

      

        this.setState({
          transferData: sorArr,
        });
      }, 2000);
    } else if (e.id == 3) {
      setTimeout(() => {
        const getValue = (star) => -star;
        sorArr.sort((a, b) => getValue() - getValue());

    

        this.setState({
          transferData: sorArr,
        });
      }, 2000);
    } else if (e.id == 1) {
      setTimeout(() => {
        const getValue = (price) => +price;
        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

        

        this.setState({
          transferData: sorArr,
        });
      }, 2000);
    } else if (e.id == 4) {
      setTimeout(() => {
        const getValue = (id) => id;
        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_data.id;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_data.id;
              })
            )
        );

        

        this.setState({
          transferData: sorArr,
        });
      }, 2000);
    }


    this.setState({
      sortingSelect: e.name,
    });

    this.setState({
      sortingId: e.id,
    });
  };

  CheckAppliedFilterLocation = () => {
  
    this.setState({ transferData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      const name = target.name;
        let applied = [...this.state.appliedFilterVal];
        const index = applied.findIndex((x) => x.label === name);
        // Remove the filter if it exists
        applied.splice(index, 1);
        this.setState({
          appliedFilterVal: applied,
        });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const transferData = [...this.state.transferListFilter];

    const result = [];
  setTimeout(() => {
    transferData && transferData.map((i, j) => {
      if (i != "no_transfer") {
          Object.values(i).map((key1, index) => {
              if(this.state.appliedFilterVal.length> 0){
              this.state.appliedFilterVal.map((item, index) => {
                    if(key1.tour_data.tour_region_name.toLowerCase().trim() === item.label) {
                      result.push(i);
                    }
                    else if(key1.tour_data.tour_location.toLowerCase().trim() === item.label){
                      result.push(i);
                    }
                 
                });
            }
          });
        }
      });
  }, 2000); 
    
  

    setTimeout(() => {
      this.setState({ transferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ transferData: transferData });
      }
    }, 2000);

  };

  CheckAppliedFilterCategory = (nodecheck) => {

    this.setState({checked : nodecheck})
    this.setState({ transferData: null });
    this.setState({
      SkeletonData: true,
    });

    //   let applied = [...this.state.appliedFilterVal];
    // //nodecheck.map((item, index) => {
    //   const newItem = (nodecheck).pop();
    //   if(newItem){
    //     this.setState({
    //       appliedFilterVal: [...applied, { label: newItem, value: newItem }],
    //     });
    //   }else{
    //     let applied = [...this.state.appliedFilterVal];

    //     let index = applied.length - 1;
  
    //     applied.splice(index, 1);
  
    //     this.setState({
    //       appliedFilterVal: applied,
    //     });
    //   }
      
   // })  
 const transferData =  [...this.state.transferListFilter];
if(nodecheck.length){
 
const result = [];
      transferData && transferData.map((i, j) => {
        if (i != "no_transfer") {
          Object.values(i).map((key1, index) => {
            
            if (nodecheck.length > 0) {
             nodecheck.map((item, index) => {
                  if (key1.tour_data.transport_mode_name.toLowerCase().trim() === item) {
                    result.push(i);
                  }
             
              });
            }
          });
        }
      });
 
    setTimeout(() => {
             this.setState({ transferData: result });
            this.setState({
              SkeletonData: false,
            });
          }, 1000);
}else{
      setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ transferData: transferData });
        this.setState({
          SkeletonData: false,
        });
      }
    }, 1000);
}

  };

  CheckAppliedFilter = () => {
    
    this.setState({ transferData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      const name = target.name;
        let applied = [...this.state.appliedFilterVal];
        const index = applied.findIndex((x) => x.label === name);
        // Remove the filter if it exists
        applied.splice(index, 1);
        this.setState({
          appliedFilterVal: applied,
        });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const transferData = [...this.state.transferListFilter];

    

    const result = [];

    

    setTimeout(() => {
      transferData.map((i, j) => {
        if (i != 'no_transfer') {
          Object.values(i).map((key1, index) => {
            if (this.state.appliedFilterVal.length > 0) {
              this.state.appliedFilterVal.map((item, index) => {
                let Minhours = 0;
                let Maxhours = 0;
                if(key1.tour_duration.flexible_id ==1){
                  if(key1.tour_duration.flexible_time ==2){
                  Minhours = parseInt(key1.tour_duration.flexible_min_hours);
                  Maxhours = parseInt(key1.tour_duration.flexible_max_hours);
                  }else{
                     Minhours = (parseInt(key1.tour_duration.flexible_min_hours)/60);
                    Maxhours = (parseInt(key1.tour_duration.flexible_max_hours)/60);
                 }
                }else{
                  Minhours = parseInt(key1.tour_duration.flexible_min_hours); 
                  Maxhours = parseInt(key1.tour_duration.flexible_max_hours); 
                }
                if (item.label == "Up to 1 hour") {
                  const minvalue = 0;
                  const maxvalue = 1;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                
                } else if (item.label == "1 to 4 hours") {
                  const minvalue = 1;
                  const maxvalue = 4;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "4 hours to 1 day") {
                  const minvalue = 4;
                  const maxvalue = 24;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "1 to 3 days") {
                  const mindays = 1;
                  const maxdays = 3;
                  if (
                    parseInt(key1.tour_duration.day_number) >= mindays &&
                    parseInt(key1.tour_duration.day_number) <= maxdays
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "3+ days") {
                  const mindays = 3;

                  if (
                    parseInt(key1.tour_duration.day_number) >= mindays &&
                    parseInt(key1.tour_duration.day_number) < mindays
                  ) {
                    result.push(i);
                  }
                }
              });
            } else {
              result.push(i);
            }
          });
        }
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ transferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ transferData: transferData });
      }
    }, 2000);
  };

  //Time Data

  CheckTimeOfDaysFilter = () => {
    
    this.setState({ transferData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      const name = target.name;
        let applied = [...this.state.appliedFilterVal];
        const index = applied.findIndex((x) => x.label === name);
        // Remove the filter if it exists
        applied.splice(index, 1);
        this.setState({
          appliedFilterVal: applied,
        });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const transferData = [...this.state.transferListFilter];

    

    const result = [];

    

    setTimeout(() => {
      transferData.map((i, j) => {
        {
          Object.values(i).map((key1, index) => {
            let checkTime = false;

            if(key1.tour_time){
            key1.tour_time.map((key2, index) => {
              if (this.state.appliedFilterVal.length > 0) {
                this.state.appliedFilterVal.map((item, index) => {
                  if (item.label == "Morning") {
                    const from_time = "05:59 am";
                    const lost_time = "12:01 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Afternoon") {
                    const from_time = "12:01 pm";
                    const lost_time = "05:00 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Evening and night") {
                    const from_time = "05:01 pm";
                    const lost_time = "12:01 am";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  }
                });
              }
            });
          }

            if (checkTime) {
              result.push(i);
            }
          });
        }
      });
    }, 2000);

  

    setTimeout(() => {
      this.setState({ transferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ transferData: transferData });
      }
    }, 2000);
  };

  changePrice = (value) => {
     this.setState({
       SkeletonData: true,
     });
 
     let name = value.min + "-" + value.max;
     let applied = [...this.state.appliedFilterVal];
 
     let catlength = applied.length;
     let PriceRangeVal = "abc";
 
     applied.map((i, j) => {
       if (i.value == "priceRange") {
         PriceRangeVal = j;
         return PriceRangeVal;
       }
     });
 
     if (PriceRangeVal == "abc") {
       this.setState({
        appliedFilterVal: [...applied, { label: name, value: "priceRange" }],
       });
     } else {
       applied[PriceRangeVal] = { label: name, value: "priceRange" };
       this.setState({
        appliedFilterVal: applied,
       });
     }
 
     const transferData = [...this.state.transferListFilter];
 
     const result = [];
 
     transferData.map((i, j) => {
       {
         Object.values(i).map((key1, index) => {
           if (
             parseInt(key1.tour_price) >= value.min &&
             parseInt(key1.tour_price) <= value.max
           ) {
             result.push(i);
           }
         });
       }
     });
 
 
     setTimeout(() => {
       this.setState({ transferData: result });
       this.setState({
         SkeletonData: false,
       });
     }, 2000);
   };
 

  // changePrice = (value) => {
  //   this.setState({ transferData: null });
   
  //   //  this.setState({ valuePrice: value })

  //   let name = value.min + "-" + value.max;
  //   let applied = [...this.state.appliedFilterVal];

  //   let catlength = applied.length;
  //   let PriceRangeVal = "abc";
  //   //var i = 0;

  //   applied.map((i, j) => {
  //     if (i.value == "priceRange") {
  //       PriceRangeVal = j;
  //       return PriceRangeVal;
  //     }
  //   });

    
  //   if (PriceRangeVal == "abc") {
  //     this.setState({
  //       appliedFilterVal: [...applied, { label: name, value: "priceRange" }],
  //     });
  //   } else {
  //     applied[PriceRangeVal] = { label: name, value: "priceRange" };
  //     this.setState({
  //       appliedFilterVal: applied,
  //     });
  //   }

  //   const transferData = [...this.state.transferListFilter];

  //   const result = [];

  //   transferData.map((i, j) => {
  //     {
  //       Object.values(i).map((key1, index) => {
          
  //         if (
  //           parseInt(key1.tour_price) >= value.min &&
  //           parseInt(key1.tour_price) <= value.max
  //         ) {
  //           result.push(i);
  //         }
  //       });
  //     }
  //   });

  

  //   setTimeout(() => {
  //     this.setState({ transferData: result });
  //   }, 2000);
  // };

  RemoveSingleFilter = (i, index, maxValue) => {
    
    this.setState({ transferData: null });

    let applied = [...this.state.appliedFilterVal];

    applied.splice(index, 1);

    this.setState({
      appliedFilterVal: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const transferData = [...this.state.transferListFilter];

    const result = [];

    setTimeout(() => {
      transferData && transferData.map((i, j) => {
        if (i != "no_transfer") {
            Object.values(i).map((key1, index) => {
                if(this.state.appliedFilterVal.length> 0){
                this.state.appliedFilterVal.map((item, index) => {
                      if(key1.tour_data.tour_region_name.toLowerCase().trim() === item.label) {
                        result.push(i);
                      }
                      else if(key1.tour_data.tour_location.toLowerCase().trim() === item.label){
                        result.push(i);
                      }
                   
                  });
              }
            });
          }
        });
    }, 2000); 

    setTimeout(() => {
      transferData.map((i, j) => {
        Object.values(i).map((key1, index) => {
          let checkTime = false;
          // Duration Data Filter
          if (this.state.appliedFilterVal.length > 0) {
            this.state.appliedFilterVal.map((item, index) => {
              let Minhours = 0;
              let Maxhours = 0;
              if(key1.tour_duration.flexible_id ==1){
                if(key1.tour_duration.flexible_time ==2){
                Minhours = parseInt(key1.tour_duration.flexible_min_hours);
                Maxhours = parseInt(key1.tour_duration.flexible_max_hours);
                }else{
                   Minhours = (parseInt(key1.tour_duration.flexible_min_hours)/60);
                  Maxhours = (parseInt(key1.tour_duration.flexible_max_hours)/60);
                }
              }else{
                Minhours = parseInt(key1.tour_duration.flexible_min_hours); 
                Maxhours = parseInt(key1.tour_duration.flexible_max_hours); 
              }
              if (item.label == "Up to 1 hour") {
                const minvalue = 0;
                const maxvalue = 1;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              
              } else if (item.label == "1 to 4 hours") {
                const minvalue = 1;
                const maxvalue = 4;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              } else if (item.label == "4 hours to 1 day") {
                const minvalue = 4;
                const maxvalue = 24;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              } else if (item.label == "1 to 3 days") {
                const mindays = 1;
                const maxdays = 3;
                if (
                  parseInt(key1.tour_duration.day_number) >= mindays &&
                  parseInt(key1.tour_duration.day_number) <= maxdays
                ) {
                  result.push(i);
                }
              } else if (item.label == "3+ days") {
                const mindays = 3;

                if (
                  parseInt(key1.tour_duration.day_number) >= mindays &&
                  parseInt(key1.tour_duration.day_number) < mindays
                ) {
                  result.push(i);
                }
              }
            });
          } else {
            result.push(i);
          }
        });
      });
    }, 2000);

    setTimeout(() => {
      transferData.map((i, j) => {
        {
          Object.values(i).map((key1, index) => {
            let checkTime = false;
            
            if(key1.tour_time){
            key1.tour_time.map((key2, index) => {
              if (this.state.appliedFilterVal.length > 0) {
                this.state.appliedFilterVal.map((item, index) => {
                  if (item.label == "Morning") {
                    const from_time = "05:59 am";
                    const lost_time = "12:01 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Afternoon") {
                    const from_time = "12:01 pm";
                    const lost_time = "05:00 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Evening and night") {
                    const from_time = "05:01 pm";
                    const lost_time = "12:01 am";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  }
                });
              }
            });
          }
            if (checkTime) {
              result.push(i);
            }
          });
          
        }
      });
    }, 2000);

  

    setTimeout(() => {
      this.setState({ transferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ transferData: transferData });
      }
    }, 2000);
  };

  RemoveMultipleFilter = (maxValue) => {
    this.setState({ transferData: null });
    this.setState({
      appliedFilterVal: [],
    });
    this.setState({ liveaboardList: null });
    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const clearCheckedItems = new Map();
    this.setState({ checkedItems: clearCheckedItems });

    const transferData = [...this.state.transferListFilter];

    setTimeout(() => {
      this.setState({ transferData: transferData });
    }, 2000);
  };

  render() {
    
    const menuClass = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
   // const { value, suggestionsTransfer } = this.state;
    const { valueSourceTransfer, suggestionsSourceTransfer } = this.state;
    const { valueDestinationTransfer, suggestionsDestinationTransfer } = this.state;
    const { valueAirportTransfer, suggestionsAirportTransfer } = this.state;
    const { valueToAirportTransfer, suggestionsToAirportTransfer } = this.state;
    const { valueAirportToCityTransfer, suggestionsAirportToCityTransfer } = this.state;
    const { valueFerryTransfer, suggestionsFerryTransfer } = this.state;
    const { valueFerryToTransfer, suggestionsFerryToTransfer } = this.state;
    const { valueCityTransfer, suggestionsCityTransfer } = this.state;

   
    const inputPropsSourceTransfer = {
      placeholder: "Enter Source Location ",
      type: "search",
      value:valueSourceTransfer,
      onChange: this.onChangeSourceTransfer,
    };
    const inputPropsDestinationTransfer = {
      placeholder: "Enter Destination Location ",
      type: "search",
      value:valueDestinationTransfer,
      onChange: this.onChangeDestinationTransfer,
    };
    const inputPropsAirportTransfer = {
      placeholder: "Enter Airport Location ",
      type: "search",
      value:valueAirportTransfer,
      onChange: this.onChangeAirportTransfer,
    };
    const inputPropsToAirportTransfer = {
      placeholder: "Enter Airport Location ",
      type: "search",
      value:valueToAirportTransfer,
      onChange: this.onChangeToAirportTransfer,
    };
    const inputPropsAirportToCityTransfer = {
      placeholder: "Enter City Location ",
      type: "search",
      value:valueAirportToCityTransfer,
      onChange: this.onChangeAirportToCityTransfer,
    };
    const inputPropsFerryTransfer = {
      placeholder: "Enter Ferry Location ",
      type: "search",
      value:valueFerryTransfer,
      onChange: this.onChangeFerryTransfer,
    };
    const inputPropsFerryToTransfer = {
      placeholder: "Enter FerryTo Location ",
      type: "search",
      value:valueFerryToTransfer,
      onChange: this.onChangeFerryToTransfer,
    };
    const inputPropsCityTransfer = {
      placeholder: "Enter City Location ",
      type: "search",
      value:valueCityTransfer,
      onChange: this.onChangeCityTransfer,
    };
    
    return (
     
      <React.Fragment>
        <div>
        <Helmet>
      <title>Best Land & ferry Transfer Deals in Seychelles- Holidays Seychelles</title>
        <meta name="description" content="Get best Land & ferry transfer deals in Seychelles, one of the most beautiful places in the world." />
        <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      </Helmet>
      <h1 style={{color:'#fff'}}>Best Land & ferry Transfer Deals in Seychelles- Holidays Seychelles</h1>
          <NewHeader page={"inner_listing"} tabName={4} />
          <div
            className={`${
              this.state.nav == true
                ? "_Hlisting_header_new"
                : "_Hlisting_header"
            }`}
          >
            <div className="container hsw">
              <div className="hsw_inner">
               

                <div className="hsw_inputBoxActivity" style={{'width': '140px'}}>
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                   Type
                  </label>
                      <select class="lbl_input latoBold font12 blueText" aria-label=".form-select-lg example"
                        id="transport_type_id_details"
                        name="transport_type_id_details"
                        onChange={this.handleTransferType}>
                            {/* <option value="0"  selected={this.state.selectedTransfertype == 0}>All</option> */}
                            <option value="1"  selected={this.state.selectedTransfertype == 1}>Airport Transfers </option>
                            <option value="2"  selected={this.state.selectedTransfertype == 2}>Intercity Transfers</option>
                            {/* <option value="4"  selected={this.state.selectedTransfertype == 2}>Intercity Transfers Round Trip</option> */}
                            <option value="3"  selected={this.state.selectedTransfertype == 3}>Ferry Transfers</option>
                            
                    
                      </select>
                 
                </div>
                {this.state.showPickupType  &&
                <div className="hsw_inputBoxActivity" style={{'width': '425px'}}>
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                   Pickup Type
                  </label>
                      <select class="lbl_input latoBold font12 blueText" aria-label=".form-select-lg example"
                        id="transport_type_id_details"
                        name="transport_type_id_details"
                        onChange={this.handlePickupType}>
                            <option value="1" selected={this.state.selectPickupType == 1}>From Airport</option>
                            <option value="2" selected={this.state.selectPickupType == 2}>To Airport </option>
                      </select>
                 
                </div>
                  }
                  { this.state.showFromAirPort && <> 
                <div
                  className="hsw_inputBoxActivity  "
                  onClick={this.showZonelistdivAirportTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                    AirPort
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalAirportTransfer.length >= 30
                      ? `${this.state.defaultZonevalAirportTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalAirportTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsAirportTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsAirportTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedAirportTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedAirportTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedAirportTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueAirportTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerAirportTransfer
                          }
                          renderSuggestion={this.renderSuggestionAirportTransfer}
                          inputProps={inputPropsAirportTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="hsw_inputBoxActivity "
                  onClick={this.showZonelistdivAirportToCityTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                   To City
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalAirportToCityTransfer.length >= 30
                      ? `${this.state.defaultZonevalAirportToCityTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalAirportToCityTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsAirportToCityTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsAirportToCityTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedAirportToCityTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedAirportToCityTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedAirportToCityTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueAirportToCityTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerAirportToCityTransfer
                          }
                          renderSuggestion={this.renderSuggestionAirportToCityTransfer}
                          inputProps={inputPropsAirportToCityTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                </> 
                     }
                     { this.state.showToAirPort && <> 
                <div
                  className="hsw_inputBoxActivity  "
                  onClick={this.showZonelistdivCityTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                     City
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalCityTransfer.length >= 30
                      ? `${this.state.defaultZonevalCityTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalCityTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsCityTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsCityTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedCityTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedCityTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedCityTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueCityTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerCityTransfer
                          }
                          renderSuggestion={this.renderSuggestionCityTransfer}
                          inputProps={inputPropsCityTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="hsw_inputBoxActivity "
                  onClick={this.showZonelistdivToAirportTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                   AirPort 
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalToAirportTransfer.length >= 30
                      ? `${this.state.defaultZonevalToAirportTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalToAirportTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsToAirportTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsToAirportTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedToAirportTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedToAirportTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedToAirportTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueToAirportTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerToAirportTransfer
                          }
                          renderSuggestion={this.renderSuggestionToAirportTransfer}
                          inputProps={inputPropsToAirportTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                </> 
                     }

            { this.state.showFerryType && <> 
                <div
                  className="hsw_inputBoxActivity  "
                  onClick={this.showZonelistdivFerryTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                     From Port
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalFerryTransfer.length >= 30
                      ? `${this.state.defaultZonevalFerryTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalFerryTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsFerryTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsFerryTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedFerryTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedFerryTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedFerryTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueFerryTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerFerryTransfer
                          }
                          renderSuggestion={this.renderSuggestionFerryTransfer}
                          inputProps={inputPropsFerryTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="hsw_inputBoxActivity "
                  onClick={this.showZonelistdivFerryToTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                  To Port
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalFerryToTransfer.length >= 30
                      ? `${this.state.defaultZonevalFerryToTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalFerryToTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsFerryToTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsFerryToTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedFerryToTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedFerryToTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedFerryToTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueFerryToTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerFerryToTransfer
                          }
                          renderSuggestion={this.renderSuggestionFerryToTransfer}
                          inputProps={inputPropsFerryToTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                </> 
                     }

              { this.state.ShowTransportType && <> 
                <div
                  className="hsw_inputBoxActivity  "
                  onClick={this.showZonelistdivSourceTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                     Source Location 
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalSourceTransfer.length >= 30
                      ? `${this.state.defaultZonevalSourceTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalSourceTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsSourceTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsSourceTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedSourceTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedSourceTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedSourceTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueSourceTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerSourceTransfer
                          }
                          renderSuggestion={this.renderSuggestionSourceTransfer}
                          inputProps={inputPropsSourceTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="hsw_inputBoxActivity "
                  onClick={this.showZonelistdivDestinationTransfer}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                    Destination Location 
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalDestinationTransfer.length >= 30
                      ? `${this.state.defaultZonevalDestinationTransfer.substring(
                          0,
                          30
                        )}...`
                      : this.state.defaultZonevalDestinationTransfer}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsDestinationTransfer ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsDestinationTransfer}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedDestinationTransfer
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedDestinationTransfer
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedDestinationTransfer
                          }
                          getSuggestionValue={this.getSuggestionValueDestinationTransfer}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerDestinationTransfer
                          }
                          renderSuggestion={this.renderSuggestionDestinationTransfer}
                          inputProps={inputPropsDestinationTransfer}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                </> 
                     }

               
                <div
                  className="hsw_inputBoxActivity"
                  onClick={this.checkinandcheckoutTransfer}
                >
                  <span className="checkinandcheckout">
                    <label
                      for="checkin"
                      className="lbl_input latoBold font12 blueText capText"
                    >
                      Add when you want to go
                    </label>
                  </span>
                  <p className="hsw_inputField font16 whiteText">
                    {this.state.selectTransferDay}
                  </p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowTransfer && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handleTransferDayClick}
                        selectedDays={this.state.selectTransferDayNew}
                        disabledDays={{ before: new Date() }}
                        month={new Date()} // Ensure the current month is displayed initially
                        fromMonth={new Date()} // Disable navigation to months before today
                      />
                    )}
                  </div>
                </div>
                <span
                  className="primaryBtn hsw_searchButton"
                  onClick={this.SearchListing}
                >
                  Search
                </span>
              </div>
            </div>
          </div>
         
          <div className="container makeFlex spaceBetween">
            <div
              className="container makeFlex spaceBetween"
              style={{ marginTop: 50, padding: "10px" }}
            >
              {/* {this.state.transferData == "no_transfer" || this.state.transferData == null ? (
                <></>
              ) : (
                <>
                  {" "} */}
                  {this.state.FiltersData == null ? (
                    <>
                      <div className="filterWrap appendRight30">
                        <div className="appendBottom35">
                          <p className="makeFlex spaceBetween end">
                            <span className="latoBold font20 blackText">
                              <Skeleton style={{ width: 240, height: 30 }} />
                            </span>
                          </p>

                          <div
                            className="filterRow"
                            id="hlistpg_fr_star_category"
                          >
                            <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                              <Skeleton style={{ width: 240, height: 26 }} />
                            </div>
                            <ul className="filterList">
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                            <Skeleton style={{ width: 240, height: 26 }} />
                          </div>
                          <ul className="filterList">
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                          </ul>
                        </div>

                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                            <Skeleton style={{ width: 240, height: 26 }} />
                          </div>
                          <ul className="filterList">
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                   ) : this.state.FiltersData == "no_transfer" ? (
                    <></>
                ) : (
                    <div className="filterWrap appendRight30">
                      {/* <p className="makeFlex spaceBetween end" style={{ marginRight: '87px' }}><span className="latoBold font20 blackText">Select Filters</span></p> */}
                      {this.state.appliedFilterVal.length > 0 ? (
                        <div className="appliedFiltersContainer">
                          <div
                            className="makeFlex spaceBetween end appendBottom20"
                            style={{
                              marginBottom: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <span className=" font20 blackText">
                              Applied Filters
                            </span>
                            <a
                              className="latoBold font12 capText"
                              href="javascript:;"
                              onClick={() =>
                                this.RemoveMultipleFilter(
                                  this.state.FiltersData.max_price
                                )
                              }
                            >
                              Clear
                            </a>
                          </div>
                          <ul className="appliedFilters">
                            {this.state.appliedFilterVal.map((i, j) => (
                              <li>
                                <span className="latoBold" style={{textTransform: 'capitalize'}}>{i.label}</span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    this.RemoveSingleFilter(
                                      i,
                                      j,
                                      this.state.FiltersData.max_price
                                    )
                                  }
                                ></a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p
                          className="makeFlex spaceBetween end"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <span className="font20 blackText">
                            Select Filters
                          </span>
                        </p>
                      )}
                        
                        <div
                        className="filterRow"
                        id="hlistpg_fr_star_category"
                        style={{ marginBottom: 30 }}
                      >
                        <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                          {" "}
                          Price
                        </div>
                        <InputRange
                          maxValue={this.state.FiltersData.max_price}
                          minValue={0}
                          value={this.state.valuePrice}
                          onChange={(value) =>
                            this.setState({ valuePrice: value })
                          }
                          onChangeComplete={(value) => this.changePrice(value)}
                        />
                        <span className="minValue">
                          <FontAwesomeIcon
                            icon={faEuroSign}
                            style={{ fontSize: "13px", color: "#555" }}
                          ></FontAwesomeIcon>{" "}
                          {this.state.valuePrice.min}
                        </span>
                        <span className="maxValue">
                          <FontAwesomeIcon
                            icon={faEuroSign}
                            style={{ fontSize: "13px", color: "#555" }}
                          ></FontAwesomeIcon>{" "}
                          {this.state.valuePrice.max}
                        </span>
                      </div>
                        <div className="filterRow" id="hlistpg_fr_star_category">
                          <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                            Location
                          </div>
                          <ul className="filterList">
                        {Object.values(this.state.FiltersData.location).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                          (i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterLocation}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.transferData == null ? true : false
                                  }
                                />
                                <span className="cat_star" style={{textTransform:'capitalize'}}>
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                        </div>
                        <div className="filterRow" id="hlistpg_fr_star_category">
                              <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                              Category
                              </div>
                          
                          { this.state.CategotyThem &&(<CheckboxTree
                              nodes={this.state.CategotyThem &&(this.state.CategotyThem)}
                              checked={this.state.checked}
                              expanded={this.state.expanded}
                              onCheck={checked => this.CheckAppliedFilterCategory(checked)}
                              onExpand={expanded => this.setState({ expanded })}
                              icons={{
                              check: <span className="rct-icon rct-icon-check" />,
                              uncheck: <span className="rct-icon rct-icon-uncheck" />,
                              halfCheck: <span className="rct-icon rct-icon-half-check" />,
                              expandClose: <span className="rct-icon rct-icon-expand-close" />,
                              expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                              expandAll: <span className="rct-icon rct-icon-expand-all" />,
                              collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                              parentClose: <span className="rct-icon rct-icon-parent-close" />,
                              parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                              leaf: <span className="rct-icon rct-icon-leaf" />,
                          }}
                          disabled={this.state.transferData == null ? true : false}
                              />)}
                          </div>

                        
                      <div className="appendBottom35">
                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                            Duration
                          </div>
                          <ul className="filterList">
                            {Duration.map((item, index) => (
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    className="icheck"
                                    onChange={this.CheckAppliedFilter}
                                    name={item.name}
                                    checked={
                                      !!this.state.checkedItems.get(item.name)
                                    }
                                    disabled={
                                      this.state.transferData == null
                                        ? true
                                        : false
                                    }
                                  />
                                  <span className="cat_star">{item.name}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      
                      <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                      Time of Day
                    </div>
                    <ul className="filterList">
                      {TimeOfDays.map((item, index) => (
                        <li style={{marginBottom: '16px'}}>
                          <label>
                            <input
                              type="checkbox"
                              className="icheck"
                              onChange={this.CheckTimeOfDaysFilter}
                              name={item.name}
                              checked={!!this.state.checkedItems.get(item.name)}
                              disabled={
                                this.state.transferData == null ? true : false
                              }
                            />
                            <span className="cat_star">{item.name}</span><br></br>
                            <span className="cat_starsmaill">{item.smallLabel}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                      
                    </div>
                  )}{" "}
               
              <div className="listingWrap">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.transferData === null ? (
                    <>
                    <span style={{ color: "#55575b", paddingTop: 0 }}>
                    </span>
                </>
                  ) : (
                    <>
                      {this.state.transferData == "no_transfer" ? (
                        <>
                        <span style={{ color: "#55575b", paddingTop: 0 }}>
                            {/* 0 result */}
                        </span>
                    </>
                      ) : (
                        <>
                          <span style={{ color: "#55575b", paddingTop: 0, fontWeight:600 }}>
                            {this.state.transferData.length == 1
                              ? "1 Result"
                              : this.state.transferData.filter(i => i != 'no_transfer').length + " Results"}
                          </span>
                        </>
                      )}
                    </>
                  )}
                 
                 {this.state.transferData != null && this.state.transferData != "no_transfer" &&(
                  <div
                    className="hsw_viewMap_content hsw_viewMap"
                    style={{ display: "flex", paddingTop: 0 }}
                  >
                    <span
                      class="latoBold"
                      style={{ color: "#55575b", fontWeight: "600 !important" }}
                    >
                      {" "}
                      Sort By:&nbsp;
                    </span>
                    <div class="customSelect">
                      <span
                        class="customSelectTitle  latoBold"
                        style={{ color: "#008cff" }}
                        onClick={this.showSorting}
                      >
                        {this.state.sortingSelect}
                      </span>
                      {this.state.isDropdownOpen && (
                        <ul class="customSelectOptions latoBold" style={{left: '35%', top: '23px'}}>
                          {SortArr.map((item, index) => (
                            <li
                              className={
                                this.state.sortingSelect == item.name
                                  ? "selectedActiveSort"
                                  : ""
                              }
                              key={index}
                              onClick={() => this.handleSortingChange(item)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>)}

                </div>
                <div className="container container-custom margin_30_95">
                  {this.state.transferData === null  ? (
                    <>
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                    </>
                  ) : this.state.transferData == "no_transfer" || this.state.transferData == null || this.state.transferData.length === 0 ?(
                    <ExperiencesSoldout experienceurl={'?t=NA=='}/>
                  ) : (
                    <>
                   
                      {this.state.transferData.map((i, j) => {
                        if (i != "no_transfer") {
                          return (
                            <>
                              {Object.values(i).map((key1, index) => (
                                <TourTransferList
                                  tour_gallery={key1.tour_gallery}
                                  tour_description={key1.tour_description}
                                  tour_price={key1.tour_price}
                                  tour_data={key1.tour_data}
                                  dateSelected={this.state.selectTransferDayNew}
                                  tour_day={key1.tour_day}
                                  tour_time={key1.tour_timing}
                                  tour_exclusion={key1.tour_exclusion}
                                  tour_inclusion={key1.tour_inclusion}
                                  specialOffer={key1.specialOffer}
                                  forMeal={key1.forMeal}
                                  transfertype={this.state.selectedTransfertype}
                                  tour_price_details={key1.tour_price_details}
                                  tour_price_schedule={key1.tour_price_schedule}
                                  tour_price_schedule_new={key1.tour_price_schedule_new}
                                  tour_duration={key1.tour_duration}
                                  freeroomCancellation={key1.freeroomCancellation}
                                  selectPickupType={this.state?.selectPickupType}
                                  defaultZonevalAirportTransfer ={this.state.defaultZonevalAirportTransfer}
                                  defaultZonevalToAirportTransfer={this.state.defaultZonevalToAirportTransfer}
                                  defaultZonevalAirportToCityTransfer={this.state.defaultZonevalAirportToCityTransfer}
                                  defaultZonevalCityTransfer= {this.state.defaultZonevalCityTransfer}
                                  defaultZonevalFerryTransfer={this.state.defaultZonevalFerryTransfer}
                                  defaultZonevalFerryToTransfer={this.state.defaultZonevalFerryToTransfer}
                                  defaultZonevalSourceTransfer= {this.state.defaultZonevalSourceTransfer}
                                  defaultZonevalDestinationTransfer= {this.state.defaultZonevalDestinationTransfer}
                                />
                              ))}
                            </>
                          );
                        }
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TawkToWidget />
      </React.Fragment>
    );
  }
}
